const versions = [
  {
    version: "2.1.0",
    title: "Platform improvements",
    date: "2024-12-10",
    changes: [
      "EPG ingest - line break added between summary and casting - #271",
      "Editorial channels - Cloning channels - #119",
      "Technical Channels - set Dvb by default - #215",
      "Global objects - Prefill i18n fields - #214",
      "Fix - Bad events in EPG json - #160",
      "Qualifications - Add a dedicated page for archived qualifications - #175",
      "Fix - bad fields naming in transponders edition - #276",
      "Qualifications - Add a dismiss action on the TPX notifications - #174",
      "Qualifications - Add Scrambled filter - #58",
      "Qualifications - Add Service Name filter - #58",
      "EPG reports - Avoid ingest errored files - #284",
      "Editorial channels - We can create a channel without logo - #202",
      "NIT Export - Fix comparaison logic - #281",
      "Qualifications - Avoid unecessary notifications about bad orbital position - #285",
      "Qualifications - Avoid unecessary deletion notifications for tech channels - #172"
    ]
  },
  {
    version: "2.0.0",
    title: "New API design and UI rework",
    date: "2023-07-20",
    changes: [
    ]
  },
  {
    version: "1.6.2",
    title: "Lineups improvements",
    date: "2021-09-06",
    changes: [
      "Lineups - Display channels count per category in statistic modal",
      "Lineups - Add text search to select channel genres more easily"
    ]
  },
  {
    version: "1.6.1",
    title: "Platform improvements",
    date: "2021-08-30",
    changes: [
      "Lineups - Display channels list per category in statistic modal",
      "Editorial channels - Display country names in alphabetical order"
    ]
  },
  {
    version: "1.6.0",
    title: "Lineups improvements",
    date: "2021-08-24",
    changes: [
      "Lineups improvements - Add the ability to move a services block at a specific destination"
    ]
  },
  {
    version: "1.5.0",
    title: "Platform improvements",
    date: "2021-08-16",
    changes: [
      "Notifications improvements - Many actions have changed to match the editorial process",
      "Channels in a lineup can be sort alphabetically by their name or their media group",
      "A new statistic modal can be displayed in the lineup page to show channel tiers usage"
    ]
  },
  {
    version: "1.4.0",
    title: "Show ZIP information in VIA export reports",
    date: "2021-08-03",
    changes: [
      "Display information (ZIP size and if it satisfies the carousel size constraint) about VIA export success or failure in the reports page"
    ]
  },
  {
    version: "1.3.9",
    title: "Fix the technical channel creation from a notification when service_name is null from probes",
    date: "2021-07-27",
    changes: [
      "Fix the technical channel creation from a notification when service_name is null from probes",
      "Show more information on the editorial channel provider_id/provider_resource_id constraint"
    ]
  },
  {
    version: "1.3.8",
    title: "Add technical channel moves",
    date: "2021-07-26",
    changes: [
      "Allow technical channel move from discarded to transfer",
      "Display current section title in browser tab bar"
    ]
  },
  {
    version: "1.3.7",
    title: "Set default Service name in Notification when empty",
    date: "2021-07-21",
    changes: [
      "Use a default Service name based on triplet from Notification while creating a technical channel"
    ]
  },
  {
    version: "1.3.6",
    title: "Fix importance in the Editorial Channel Edit Modal",
    date: "2021-07-09",
    changes: [
      "Fixing a bug in the Editorial Channel Edit Modal that does not allow to go to the next step if the user does not select an importance value"
    ]
  },
  {
    version: "1.3.5",
    title: "Importance object",
    date: "2021-07-06",
    changes: [
      "Adding \"Importance\" in Global Objects",
      "Can manage the importance of an editorial channel",
      "Editing channels view in the lineups page"
    ]
  },
  {
    version: "1.3.4",
    title: "Fixes and batch of stories",
    date: "2021-06-30",
    changes: [
      "Fixing logo management in modals",
      "Fixing the \"+\" button to add a logo (Story 76)",
      "Able to filter editorial channels with logos (Story 76)",
      "Remove the search bar in the Lineups page (Story 84)",
      "Add a title in the lineup page when a lineup is opened. A button allow to close the lineup (Story 84)",
      "Removing the pagination in the lineup page (Story 84)",
      "When adding a new editorial channel, it sets it in the \"archived\" status (Story 88)",
      "Fixing the number sort in table (Story 90)"
    ]
  },
  {
    version: "1.3.3",
    title: "Service lock",
    date: "2021-06-28",
    changes: [
      "Able to lock lineups services, to protect against LCNS modifications"
    ]
  },
  {
    version: "1.3.2",
    title: "Notifications - Improvements",
    date: "2021-06-17",
    changes: [
      "Add a \"transfer list\" for the technical channnels notifications",
      "Rework of actions for technical channels notifications",
      "Add a new status for technical channels: \"To transfer\". This status means a technical channel was created from a notification and needs to be assigned to a lineup"
    ]
  },
  {
    version: "1.3.1",
    title: "NIT integration - Improvements",
    date: "2021-04-19",
    changes: [
      "Add the HomeTS attribute on the transponder object",
      "Reordering columns in transponders page",
      "Able to compare the current NIT generation to a previous one",
      "NIT generation files are now stored in a ZIP file in the bucket"
    ]
  },
  {
    version: "1.3.0",
    title: "NIT integration - Phase #1",
    date: "2021-04-13",
    changes: [
      "Add NIT Driver in Drivers page",
      "Add NIT generation in Report page"
    ]
  },
  {
    version: "1.2.10",
    title: "Fix lineups page refresh behaviour",
    date: "2021-04-01",
    changes: [
      "Fix lineups page refresh behaviour"
    ]
  },
  {
    version: "1.2.9",
    title: "Fix deselect after deleting an editorial channel",
    date: "2021-03-31",
    changes: [
      "Fix deselect after deleting an editorial channel"
    ]
  },
  {
    version: "1.2.8",
    title: "Improve Editorial Channels pages and interraction - Story 79",
    date: "2021-03-31",
    changes: [
      "The editorial channels page contains a \"All Editorial Channels\" tab which shows all editorial channels",
      "The editorial channels page shows a \"status\" column",
      "When associating/disassociating an editorial channel to/from a service, it handles status (active/archived) management"
    ]
  },
  {
    version: "1.2.7",
    title: "Fix editorial channels filters and sorting",
    date: "2021-03-03",
    changes: [
      "Editorial Channels filters and sorting are now fully functional"
    ]
  },
  {
    version: "1.2.6",
    title: "Historic version",
    date: "2021-03-02",
    changes: [
      "Historic version"
    ]
  }
];

export default versions;

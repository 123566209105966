import countryList from "country-list";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import InventoryIcon from "@mui/icons-material/Inventory";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Button, Divider, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createEditorialChannel as createEditorialChannelAction,
  indexEditorialChannelsFilters as indexEditorialChannelsFiltersAction,
  indexEditorialChannels as indexEditorialChannelsAction,
  updateEditorialChannel as updateEditorialChannelAction,
  exportEditorialChannels as exportEditorialChannelsAction
} from "../../actions/editorial_channels";
import {
  listEditorialChannels as listEditorialChannelsSelector,
  listEditorialChannelsFilters as listEditorialChannelsFiltersSelector
} from "../../selectors/editorial_channels";
import { isLoading as isLoadingSelector } from "../../selectors/loaders";
import {
  listImportances as listImportancesSelector
} from "../../selectors/importances";
import { exportAsFile } from "../../helpers/utils";

import FullLoader from "../../components/FullLoader";
import AddEditorialChannelModal from "../../components/EditorialChannelModal/AddEditorialChannelModal";
import EditEditorialChannelModal from "../../components/EditorialChannelModal/EditEditorialChannelModal";
import RemoveEditorialChannelModal from "../../components/EditorialChannelModal/RemoveEditorialChannelModal";

import DataTable from "../../components/DataTable";
import FiltersInputs from "../../components/FiltersInputs";

import { customGridOperators } from "../../utils/constant";

class EditorialChannels extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: "active",

      /* filters */
      filters: [],

      selectedChannelsIds: [],

      currentChannelId: null,
      currentChannelIds: [],

      menuAnchorEl: null,
      openedMenu: null,

      addChannelModalOpened: false,
      editChannelModalOpened: false,
      removeChannelModalOpened: false,
      editorialChannelIdToDuplicate: null,

      removeMode: "archiving",

      globalLoading: false
    };
  }

  componentDidMount() {
    const { indexEditorialChannelsFilters } = this.props;

    indexEditorialChannelsFilters();
  }

  setSelectedChannelsIds = newSelection => {
    this.setState({
      selectedChannelsIds: newSelection,
    });
  };

  /* Menus methods */

  setMenuAnchorEl = target => {
    this.setState({
      menuAnchorEl: target
    });
  };

  setOpenedMenu = menuId => {
    this.setState({
      openedMenu: menuId
    });
  };

  openMenu = (event, menuId) => {
    this.setMenuAnchorEl(event.currentTarget);
    this.setOpenedMenu(menuId);
  };

  closeMenu = menuId => {
    this.setMenuAnchorEl(null);
    this.setOpenedMenu(menuId);
  };

  /* filter methods */
  setFilters = newFilters => {
    this.setState({
      filters: newFilters
    });
  };

  /* Tabs */

  changeTab = (event, tabType) => {
    this.setState({
      currentTab: tabType
    });
  };

  /* Edit Modal */

  openEditChannelModal = currentChannelId => {
    this.setState({
      currentChannelId,
      editChannelModalOpened: true
    });
  };

  closeEditChannelModal = () => {
    this.setState({
      currentChannelId: null,
      editChannelModalOpened: false
    });
  };

  duplicateEditorialChannel = currentChannelId => {
    this.setState({
      currentChannelId: null,
      editorialChannelIdToDuplicate: currentChannelId,
      editChannelModalOpened: false,
      addChannelModalOpened: true
    });
  };

  /* Add Modal */

  openAddChannelModal = () => {
    this.setState({
      currentChannelId: null,
      addChannelModalOpened: true
    });
  };

  closeAddChannelModal = () => {
    const { indexEditorialChannelsFilters } = this.props;

    indexEditorialChannelsFilters();
    this.setState({
      addChannelModalOpened: false,
      editorialChannelIdToDuplicate: null
    });
  };

  /* Remove Modal */

  openRemoveChannelModal = removeMode => {
    if (this.state.selectedChannelsIds.length > 0) {
      this.setState({
        currentChannelIds: this.state.selectedChannelsIds,
        removeMode,
        removeChannelModalOpened: true
      });
    }
  };

  closeRemoveChannelModal = () => {
    this.setState({
      currentChannelIds: [],
      removeMode: "archiving",
      removeChannelModalOpened: false
    });
  };

  activeChannels = async () => {
    const { updateEditorialChannel, addToast } = this.props;
    const editorialChannelIds = this.state.selectedChannelsIds;

    this.setState({ globalLoading: true });

    let success = true;

    for (const element of editorialChannelIds) {
      const ret = await updateEditorialChannel(element, { status: "active" });

      if (!ret.isSuccess) {
        success = false;
      }
    }

    this.setState({ globalLoading: false });

    if (success) {
      addToast("success", "Success", "Channel(s) successfully activated!");
    } else {
      addToast("error", "Error", "Error while activating Channels. Try again.");
    }
  };

  exportCSV = async () => {
    const { exportEditorialChannels, addToast } = this.props;
    const response = await exportEditorialChannels();

    if (!response.isSuccess) {
      addToast("error", "Error", "Error while exporting Channels. Try again.");
      return;
    }

    exportAsFile(response.data, "editorial_channels.csv");
  };

  render() {
    const {
      currentTab,
      filters,
      currentChannelId,
      currentChannelIds,
      globalLoading,
      addChannelModalOpened,
      editChannelModalOpened,
      removeChannelModalOpened,
      removeMode,
      selectedChannelsIds,
      menuAnchorEl,
      openedMenu,
      editorialChannelIdToDuplicate
    } = this.state;

    const {
      editorialChannels,
      editorialChannelsFilters,
      importances,
      isEditorialChannelCreating,
      isEditorialChannelLoading,
      isEditorialChannelEditing,
      isExportingEditorialChannels
    } = this.props;

    const menuOpen = Boolean(menuAnchorEl);

    const sortedCountryList = countryList.getData()
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      })
      .map(country => ({ name: country.name, value: country.code }));

    const sortedImportancesList = importances
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      })
      .map(({ name }) => ({ name, value: name }));

    const sortedAudioLanguageList = ((editorialChannelsFilters.find(f => f.name === "audio_language") || {}).values || [])
      .filter(v => !!v)
      .sort()
      .map(v => ({ name: v, value: v }));

    const primaryFilters = [
      {
        text: "Logo",
        name: "logo",
        values: [{ name: "With logo", value: true }, { name: "Without logo", value: false }]
      },
      {
        text: "Source of program",
        name: "provider_id",
        values: ((editorialChannelsFilters.find(f => f.name === "provider_id") || {}).values || []).map(v => ({ name: v, value: v }))
      },
      {
        text: "Country",
        name: "country",
        values: [{ name: "No country", value: false }].concat(sortedCountryList)
      },
      {
        text: "Audio Language",
        name: "audio_language",
        values: [{ name: "No audio language", value: false }, { name: "Empty audio language", value: "none" }].concat(sortedAudioLanguageList)
      },
      {
        text: "Importance",
        name: "importance_id",
        values: [{ name: "No importance", value: false }].concat(sortedImportancesList)
      }
    ];

    const statuedEditorialChannels = editorialChannels.filter(ec => currentTab === "all" || ec.status === currentTab);
    const totalItemsCount = statuedEditorialChannels.length;

    const tabs = [
      {
        name: <>
          {" All Editorial Channels"}
          {currentTab === "all"
                      && <span className="tab-nav-number"> ({totalItemsCount})</span>}
        </>,
        slug: "all",
        icon: <LanguageIcon />
      },
      {
        name: <>
          {"Active"}
          {currentTab === "active" && <span className="tab-nav-number"> ({totalItemsCount})</span>}
        </>,
        slug: "active",
        icon: <CheckIcon sx={{ color: "green" }} />
      },
      {
        name: <>
          {"Archived"}
          {currentTab === "archived" && <span className="tab-nav-number"> ({totalItemsCount})</span>}
        </>,
        slug: "archived",
        icon: <InventoryIcon sx={{ color: "orange" }} />
      }
    ];

    const getLogo = params => params.row.logos && params.row.logos.length > 0;
    const getChannelName = params => (params.row.locales.en_GB || params.row.loacales[Object.keys(params.row.locales).find(localeKey => params.row.locales[localeKey].name)] || {}).name;

    const getAudioLanguage = params => {
      const audioLanguage = params.row.audio_language;
      if (audioLanguage) {
        return audioLanguage;
      } else if (audioLanguage === null) {
        return false;
      }
      return "none";
    };

    return (
      <Box className="page-content">
        {(!!isEditorialChannelCreating || !!isEditorialChannelEditing || !!isEditorialChannelLoading || !!globalLoading) && <FullLoader />}

        {!isEditorialChannelCreating && !isEditorialChannelLoading && !isEditorialChannelEditing && !globalLoading
          && <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Tabs
                sx={{
                  marginBottom: "15px",
                  ".MuiTabs-indicator": {
                    display: "none"
                  },
                }}
                value={currentTab}
                onChange={this.changeTab}
              >
                {tabs.map(tab => <Tab
                  sx={{
                    minHeight: "auto",
                    marginRight: "20px",
                    borderBottom: "solid 3px",
                    borderColor: "secondary.main",
                    "&.Mui-selected": {
                      borderColor: "text.primary"
                    }
                  }}
                  key={tab.slug}
                  value={tab.slug}
                  label={tab.name}
                  icon={tab.icon}
                  iconPosition="start"
                />)}
              </Tabs>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  onClick={() => this.exportCSV()}
                  disabled={ isExportingEditorialChannels }
                >
                  Export CSV {isExportingEditorialChannels && " (loading...)"}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.openAddChannelModal()}>
                  New channel
                </Button>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="flex-start">
              <Button
                id="actions-button"
                sx={{
                  borderColor: "primary.smokeBorder",
                }}
                variant="outlined"
                aria-controls={menuOpen && openedMenu === "actions-menu" ? "actions-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen && openedMenu === "actions-menu" ? "true" : undefined}
                disableElevation
                onClick={e => this.openMenu(e, "actions-menu")}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={ !selectedChannelsIds.length }
              >
                Actions
              </Button>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "actions-button",
                }}
                id="actions-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen && openedMenu === "actions-menu"}
                onClose={() => this.closeMenu()}
              >
                { currentTab !== "archived"
                  && <MenuItem
                    onClick={() => {
                      this.closeMenu();
                      this.openRemoveChannelModal("archiving");
                    }}
                    disableRipple
                  >
                    Archive selected channels
                  </MenuItem>
                }
                { currentTab !== "active"
                  && <MenuItem
                    sx={{
                      color: "red"
                    }}
                    onClick={() => {
                      this.closeMenu();
                      this.activeChannels();
                    }}
                    disableRipple
                  >
                    Active selected channels
                  </MenuItem>
                }
                <Divider />
                <MenuItem
                  sx={{ color: "error.main" }}
                  onClick={() => {
                    this.closeMenu();
                    this.openRemoveChannelModal("deleting");
                  }}
                  disableRipple
                >
                    Delete selected channels
                </MenuItem>
              </Menu>
              <FiltersInputs primaryFilters={primaryFilters} onFiltersChange={this.setFilters} searchFilterField="name" searchFilterLabel="Channel"/>
            </Stack>

            <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
              <DataTable
                cols={[
                  { headerName: "Id", field: "id", sortable: true, searchable: true, flex: 1 },
                  { headerName: "Status",
                    field: "status",
                    renderCell: params => params.value === "active" ? <CheckIcon sx={{ color: "green" }} /> : <InventoryIcon sx={{ color: "orange" }} />,
                    flex: 1
                  },
                  {
                    headerName: " ",
                    field: "logo",
                    valueGetter: getLogo,
                    renderCell: params => params.value && <Stack justifyContent="center" sx={{ backgroundColor: "#dddddd", borderRadius: "5px", width: "45px", height: "45px" }}>
                      <img style={{ maxWidth: "45px", maxHeight: "45px" }} src={ params.row.logos[0].file_url } alt="" />
                    </Stack>,
                    width: 60,
                    sortable: false,
                    filterOperators: customGridOperators
                  },
                  {
                    headerName: "Channel",
                    field: "name",
                    valueGetter: getChannelName,
                    searchable: true,
                    flex: 2,
                    filterOperators: customGridOperators
                  },
                  {
                    headerName: "Provider Id",
                    field: "provider_id",
                    searchable: true,
                    flex: 1,
                    filterOperators: customGridOperators
                  },
                  { headerName: "Provider Resource Id", field: "provider_resource_id", searchable: true, flex: 1 },
                  {
                    headerName: "Importance",
                    field: "importance_id",
                    valueGetter: params => (importances.find(i => i.id === params.value) || {}).name || false,
                    renderCell: params => params.value ?? " ",
                    filterOperator: "equals",
                    searchable: true,
                    flex: 1,
                    filterOperators: customGridOperators
                  },
                  { headerName: "Language", field: "audio_language", searchable: true, flex: 1 },
                  { headerName: "Modified", field: "updated_at", flex: 2 },
                  {
                    headerName: "Country",
                    field: "country",
                    valueGetter: params => params.value ?? false,
                    filterOperators: customGridOperators
                  },
                  {
                    headerName: "Audio Language",
                    field: "audio_language",
                    valueGetter: getAudioLanguage,
                    renderCell: params => params.value ?? "",
                    filterOperators: customGridOperators
                  }
                ]}
                invisibleCols={{ country: false, audio_language: false }}
                data={statuedEditorialChannels}
                pagination
                checkboxSelection
                filters={filters}
                onRowSelectionModelChange={this.setSelectedChannelsIds}
                onRowClick={id => this.openEditChannelModal(id)}
              />
            </div>

          </>
        }

        {/* MODAL */}

        <AddEditorialChannelModal
          modalOpened={addChannelModalOpened}
          closeAddChannelModal={this.closeAddChannelModal}
          editorialChannelIdToDuplicate={editorialChannelIdToDuplicate}
          key={editorialChannelIdToDuplicate}
        />

        <EditEditorialChannelModal
          currentChannelId={currentChannelId?.id}
          modalOpened={editChannelModalOpened}
          closeEditChannelModal={this.closeEditChannelModal}
          duplicateEditorialChannel={this.duplicateEditorialChannel}
        />

        <RemoveEditorialChannelModal
          currentChannelIds={currentChannelIds}
          removeMode={removeMode}
          modalOpened={removeChannelModalOpened}
          closeRemoveChannelModal={this.closeRemoveChannelModal}
        />
      </Box>
    );
  }
}

EditorialChannels.propTypes = {
  editorialChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
  editorialChannelsFilters: PropTypes.arrayOf(PropTypes.object).isRequired,

  importances: PropTypes.arrayOf(PropTypes.object).isRequired,

  isEditorialChannelCreating: PropTypes.bool.isRequired,
  isEditorialChannelLoading: PropTypes.bool.isRequired,
  isEditorialChannelEditing: PropTypes.bool.isRequired,
  isExportingEditorialChannels: PropTypes.bool.isRequired,

  indexEditorialChannelsFilters: PropTypes.func.isRequired,
  updateEditorialChannel: PropTypes.func.isRequired,
  exportEditorialChannels: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    editorialChannels: listEditorialChannelsSelector(state),
    editorialChannelsFilters: listEditorialChannelsFiltersSelector(state),
    importances: listImportancesSelector(state),

    isEditorialChannelCreating: isLoadingSelector(state, createEditorialChannelAction.toString()),
    isEditorialChannelLoading: isLoadingSelector(state, indexEditorialChannelsAction.toString()),
    isEditorialChannelEditing: isLoadingSelector(state, updateEditorialChannelAction.toString()),
    isExportingEditorialChannels: isLoadingSelector(state, exportEditorialChannelsAction.toString()),
  };
}

const mapDispatchToProps = {
  indexEditorialChannelsFilters: indexEditorialChannelsFiltersAction,
  updateEditorialChannel: updateEditorialChannelAction,
  exportEditorialChannels: exportEditorialChannelsAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorialChannels);

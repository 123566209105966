/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faImage, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Box, DialogContent, Typography, Button, Alert, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import AceEditor from "react-ace";
import countryList from "country-list";
import Modal from "../Modal/Modal";
import withStepper from "../Modal/withStepper";

import { addToast as addToastAction } from "../../actions/toasts";

import {
  createEditorialChannel as createEditorialChannelAction
} from "../../actions/editorial_channels";

import {
  listImportances as listImportancesSelector
} from "../../selectors/importances";
import {
  listEditorialChannels
} from "../../selectors/editorial_channels";

import { getImageAttributes } from "../../helpers/images";

import {
  toJsonString,
  isValidJson,
  isValidLocaleJson
} from "../../helpers/utils";

const initialState = {
  provider_id: "",
  provider_resource_id: "",
  importance_id: "",
  audio_language: "",
  country: "",
  country_group: "",
  media_group: "",
  website_url: "",
  status: "archived",
  default_language: "en_GB",
  languages: ["en_GB"],
  locales: toJsonString("{\"en_GB\":{\"name\":\"Channel\",\"description\":\"\"}}"),
  logos: [],
  logosPreviews: [],
};

class AddEditorialChannelModal extends PureComponent {
  constructor(props) {
    super(props);

    const { editorialChannelIdToDuplicate, editorialChannels } = props;
    const editorialChannelToDuplicate = editorialChannelIdToDuplicate ? editorialChannels.find(ec => ec.id === editorialChannelIdToDuplicate) : null;

    this.state = {
      ...initialState,
      ...editorialChannelToDuplicate
        ? {
          importance_id: editorialChannelToDuplicate.importance_id,
          audio_language: editorialChannelToDuplicate.audio_language,
          country: editorialChannelToDuplicate.country,
          country_group: editorialChannelToDuplicate.country_group,
          media_group: editorialChannelToDuplicate.media_group,
          website_url: editorialChannelToDuplicate.website_url,
          locales: JSON.stringify(
            Object.keys(editorialChannelToDuplicate.locales).reduce((acc, key) => {
              acc[key] = { name: "", description: "" };
              return acc;
            }, {}),
            null,
            2
          ),
        }
        : {}
    };

    this.fileInput = React.createRef();

    this.addLogo = this.addLogo.bind(this);
  }

  changeInput(inputName, event) {
    const { value } = event?.target || "";
    const { default_language, languages } = this.state;
    const isDefaultLanguageValid = this.isValid("default_language");
    let isSet = false;

    if (inputName === "locales") {
      if (isValidJson(event)) {
        this.setState({ "locales": event });
        const localesJson = JSON.parse(event);
        const localesJsonKeys = Object.keys(localesJson);

        if (JSON.stringify(localesJsonKeys) !== JSON.stringify(languages)) {
          this.setState({ languages: localesJsonKeys });
          if (!localesJsonKeys.includes(default_language) || !isDefaultLanguageValid) {
            if (localesJsonKeys.length === 0) {
              this.setState({ default_language: "" });
            } else {
              localesJsonKeys.forEach(localeKey => {
                if (localeKey.length === 5 && !isSet) {
                  this.setState({ default_language: localeKey });
                  isSet = true;
                }
              });
              if (!isSet) {
                this.setState({ default_language: localesJsonKeys[0] });
              }
            }
          }
        }
      } else {
        this.setState({ default_language: "", languages: [""], locales: event });
      }
    } else {
      this.setState({ [inputName]: value });
    }
  }

  triggerLogoInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileInput.current.click();
  }

  addLogo(event) {
    event.preventDefault();
    event.stopPropagation();

    const { logos, logosPreviews } = this.state;

    if (this.fileInput.current.files.length > 0) {
      const file = this.fileInput.current.files[0];

      // eslint-disable-next-line no-param-reassign
      event.target.value = null;

      const newLogos = [...logos];

      file.id = new Date().valueOf();

      newLogos.push(file);

      this.setState({ logos: newLogos });

      const reader = new FileReader();

      reader.onload = e => {
        const newLogosPreviews = [...logosPreviews];

        newLogosPreviews.push({
          id: file.id,
          url: e.target.result
        });

        this.setState({
          logosPreviews: newLogosPreviews
        });
      };

      reader.readAsDataURL(file);
    }
  }

  removeLogo(logoId) {
    const { logos, logosPreviews } = this.state;
    const newLogos = logos.filter(l => l.id !== logoId);
    const newLogosPreviews = logosPreviews.filter(l => l.id !== logoId);

    this.setState({ logos: newLogos, logosPreviews: newLogosPreviews });
  }

  async addChannel() {
    const {
      provider_id,
      provider_resource_id,
      importance_id,
      default_language,
      audio_language,
      media_group,
      country,
      country_group,
      website_url,
      status,
      locales,

      logos
    } = this.state;

    const { createEditorialChannel, addToast } = this.props;

    const editorialChannelData = {
      provider_id,
      provider_resource_id,
      importance_id: importance_id || null,
      default_language,
      audio_language,
      media_group,
      country: country || "",
      country_group,
      website_url,
      status,
      locales: JSON.parse(locales)
    };

    const logosData = [];

    for (let i = 0; i < logos.length; i += 1) {
      const imageAttributes = await getImageAttributes(logos[i]);
      const logoData = new FormData();

      logoData.append("file", logos[i]);
      logoData.append("delivery", "dvb");
      logoData.append("file_type", imageAttributes.fileType);
      logoData.append("width", imageAttributes.width);
      logoData.append("height", imageAttributes.height);

      logosData.push(logoData);
    }

    const ret = await createEditorialChannel({
      editorialChannel: editorialChannelData,
      logos: logosData
    });

    if (ret.isSuccess) {
      addToast("success", "Success", "Editorial channel successfully added!");
      this.closeModal();
    } else {
      addToast("error", "Error", "Error while adding Editorial Channel. Try again.");
    }
  }

  closeModal() {
    const { closeAddChannelModal } = this.props;

    this.setState(initialState);
    closeAddChannelModal();
  }

  isValid(inputName) {
    if (inputName instanceof Array) {
      return inputName.every(i => this.isValid(i));
    }

    if (inputName === "default_language" && this.state[inputName]?.length !== 5) {
      return false;
    }

    return !!this.state[inputName];
  }

  isProviderCoupleExist() {
    const { provider_id, provider_resource_id } = this.state;
    const { editorialChannels } = this.props;

    return this.isValid("provider_id", "provider_resource_id") && editorialChannels.some(ec => ec.provider_id === provider_id && ec.provider_resource_id === provider_resource_id);
  }

  isLocalesValid() {
    const { locales, default_language } = this.state;

    if (!this.isValid("locales") || !isValidJson(locales)) {
      return "Invalid JSON format for locales. Please ensure the JSON syntax is correct";
    }

    if (!isValidLocaleJson(locales)) {
      return "Locales json is not valid. Locales json should contain language keys with length equals to 5 characters";
    }

    if (this.isValid("locales")
      && isValidJson(locales)
      && isValidLocaleJson(locales)
      && this.isValid("default_language")
      && default_language.length === 5
    ) {
      let newDefaultLanguage = default_language;

      if (!JSON.parse(locales)[default_language]) {
        newDefaultLanguage = Object.keys(JSON.parse(locales)).find(localeKey => localeKey.length === 5);
      }

      const currentChannelName = JSON.parse(locales)[newDefaultLanguage].name || "";

      if (!currentChannelName || currentChannelName.length === 0 || currentChannelName.toString() !== currentChannelName) {
        return "You have to define a name entry in the locales JSON. For example: { \"en_GB\" : { \"name\": \"my channel\" } }";
      }
    }
    return "";
  }

  isNextDisabled() {
    const { currentStep } = this.props;

    switch (currentStep) {
      case 0:
        return !this.isValid(["provider_id", "provider_resource_id", "default_language"]) || !this.isLocalesValid().length === 0;
      default:
        return false;
    }
  }

  modalContent() {
    const { locales, provider_id, provider_resource_id, audio_language, importance_id, country, country_group, media_group, website_url, status, default_language, languages, logosPreviews } = this.state;
    const { importances, currentStep, isLastStep, stepperComponent } = this.props;

    const isProviderIdValid = this.isValid("provider_id") && !this.isProviderCoupleExist();
    const isProviderResourceIdValid = this.isValid("provider_resource_id") && !this.isProviderCoupleExist();
    const isProviderCoupleExist = this.isProviderCoupleExist();
    const isStatusValid = this.isValid("status");
    const isLocalesValid = this.isLocalesValid();
    const isDefaultLanguageValid = this.isValid("default_language");

    const sortedCountryList = countryList.getData().sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    const sortedImportancesList = importances.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    return (
      <DialogContent sx={{ display: "flex", gap: 4, flexDirection: "column", marginTop: 1, width: "90%", alignItems: "center", alignSelf: "center" }}>
        {stepperComponent}
        { currentStep === 1
        && <Box sx={{ display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
          { logosPreviews.length === 0
            ? <Alert severity="error">
              You have to add at least one logo
            </Alert>
            : <Box sx={{ display: "flex", justifyContent: "center", gap: 10, width: "100%", alignItems: "center", flexWrap: "wrap" }}>
              {logosPreviews.map(logo => <Box key={logo.id} >
                <Box sx={{ display: "flex", alignItems: "flex-end", color: "error.main" }}>
                  <img src={logo.url} alt="logo" style={{ maxWidth: "150px", maxHeight: "150px", width: "auto", height: "auto", margin: "10px", borderRadius: "5px" }}/>
                  <FontAwesomeIcon size="xl" icon={faTrash} onClick={() => this.removeLogo(logo.id)} style={{ cursor: "pointer" }}/>
                </Box>
              </Box>)}
            </Box>}
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <FontAwesomeIcon size="8x" icon={faImage} />
            <label style={{ cursor: "pointer" }} htmlFor="file-input">
              <FontAwesomeIcon size="xl" icon={faCirclePlus} />
              <input
                id="file-input"
                type="file"
                accept="image/png, image/jpeg"
                ref={this.fileInput}
                onChange={this.addLogo}
                style={{ display: "none" }}
              />
            </label>
          </Box>
        </Box>
        }
        { currentStep !== 1
        && <Box sx={{ display: "flex", width: "100%", gap: 4 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
            {isProviderCoupleExist && <Alert sx={{ width: "100%" }} severity="error" >
              This Provider Id / Provider Resource Id couple is already existing
            </Alert>}
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField sx={{ width: "100%" }}
                inputProps={{ readOnly: isLastStep }}
                label="Provider id*"
                value={provider_id}
                error={!isProviderIdValid}
                onChange={e => {
                  this.changeInput("provider_id", e);
                }}/>
              <TextField sx={{ width: "100%" }}
                inputProps={{ readOnly: isLastStep }}
                label="Provider Resource id*"
                value={provider_resource_id}
                error={!isProviderResourceIdValid}
                onChange={e => {
                  this.changeInput("provider_resource_id", e);
                }}/>
            </Box>
            <TextField sx={{ width: "100%" }}
              inputProps={{ readOnly: isLastStep }}
              label="Audio Language"
              value={audio_language}
              onChange={e => {
                this.changeInput("audio_language", e);
              }}/>
            <FormControl fullWidth>
              <InputLabel id="importance-label">Importance</InputLabel>
              <Select
                inputProps={{ readOnly: isLastStep }}
                labelId="importance-label"
                value={importance_id || ""}
                label="Importance"
                onChange={e => {
                  this.changeInput("importance_id", e);
                }}
              >
                <MenuItem value="">--- No Importance ---</MenuItem>
                {sortedImportancesList.map(i => <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                inputProps={{ readOnly: isLastStep }}
                labelId="country-label"
                value={country || ""}
                label="Country"
                onChange={e => {
                  this.changeInput("country", e);
                }}
              >
                <MenuItem value="">--- No Country ---</MenuItem>
                {sortedCountryList.map(c => <MenuItem key={c.code} value={c.code}>{c.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField sx={{ width: "100%" }}
                inputProps={{ readOnly: isLastStep }}
                label="Country Group"
                value={country_group}
                onChange={e => {
                  this.changeInput("country_group", e);
                }}
              />
              <TextField sx={{ width: "100%" }}
                inputProps={{ readOnly: isLastStep }}
                label="Media Group"
                value={media_group}
                onChange={e => {
                  this.changeInput("media_group", e);
                }}
              />
            </Box>
            <TextField sx={{ width: "100%" }}
              inputProps={{ readOnly: isLastStep }}
              label="Website URL"
              value={website_url}
              onChange={e => {
                this.changeInput("website_url", e);
              }}/>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="status-label">
                  Status
                </InputLabel>
                <Select
                  inputProps={{ readOnly: isLastStep }}
                  labelId="status-label"
                  value={status}
                  error={!isStatusValid}
                  label="Status"
                  onChange={e => {
                    this.changeInput("status", e);
                  }}
                >
                  <MenuItem value="archived">
                    Archived
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography sx={{ fontSize: "small", textAlign: "center" }}>
              As this new channel won't be associated to a lineup yet,it will be archived by default.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
            {!isDefaultLanguageValid && <Alert sx={{ width: "100%" }} severity="error">
              Default Language should be 5 characters length
            </Alert>}
            <FormControl fullWidth error={!isDefaultLanguageValid}>
              <InputLabel id="default-language-label">
                Default language
              </InputLabel>
              <Select
                sx={{ color: !isDefaultLanguageValid && "state.error" }}
                inputProps={{ readOnly: isLastStep }}
                labelId="default-language-label"
                value={default_language || ""}
                error={!isDefaultLanguageValid}
                label="Default language"
                onChange={e => {
                  this.changeInput("default_language", e);
                }}
              >
                <MenuItem value="" disabled>
                  Select a default language
                </MenuItem>
                {languages.map(dl => <MenuItem key={dl} value={dl}>{dl}</MenuItem>)}
              </Select>
            </FormControl>
            {isLocalesValid.length !== 0 && <Alert sx={{ width: "100%" }} severity="error" >{isLocalesValid}</Alert>}
            <AceEditor
              mode="json"
              theme="tomorrow"
              fontSize="0.9rem"
              name="editorial-channel-add-locales-edit"
              height="300px"
              width="inherit"
              readOnly={isLastStep}
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              value={locales}
              onChange={e => {
                this.changeInput("locales", e);
              }}
              editorProps={{ $blockScrolling: true }}
            />
            {isLastStep
              && <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap", mt: 3, alignItems: "center" }}>
                <FontAwesomeIcon size="xl" icon={faImage} />
                {logosPreviews.map(logo =>
                  <Box key={logo.id}>
                    <img
                      src={logo.url}
                      alt="logo"
                      style={{ maxWidth: "75px", maxHeight: "75px", width: "auto", height: "auto", borderRadius: "5px" }}
                    />
                  </Box>
                )}
              </Box>
            }
          </Box>
        </Box>
        }
      </DialogContent>
    );
  }

  modalActions() {
    const { currentStep, isLastStep, previousStep, nextStep } = this.props;
    const isNextDisabled = this.isNextDisabled();

    return (
      [
        <Button variant="outlined"
          key="cancel"
          onClick={() => {
            this.closeModal();
          }} >
          Cancel
        </Button>,
        currentStep > 0 && <Button variant="outlined"
          key="previous"
          onClick={() => {
            previousStep();
          }}>
          Previous
        </Button>,
        !isLastStep && <Button variant="contained"
          key="next"
          disabled={isNextDisabled}
          color="secondary"
          onClick={() => {
            nextStep();
          }}>
          Next
        </Button>,
        isLastStep && <Button variant="contained"
          key="save"
          color="secondary"
          onClick={() => {
            this.addChannel();
          }}>
            Save
        </Button>
      ]
    );
  }

  render() {
    const {
      modalOpened,
    } = this.props;

    return (
      <Modal
        isOpen={modalOpened}
        title="Add editorial channel"
        customModalContent={() => this.modalContent()}
        closeModal={() => this.closeModal()}
        actions={() => this.modalActions()}
        actionAlign="flex-end"
        maxWidth="lg"
      />
    );
  }
}

AddEditorialChannelModal.defaultProps = {

};

AddEditorialChannelModal.propTypes = {
  editorialChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
  importances: PropTypes.arrayOf(PropTypes.object).isRequired,
  editorialChannelIdToDuplicate: PropTypes.number,
  modalOpened: PropTypes.bool.isRequired,
  closeAddChannelModal: PropTypes.func.isRequired,

  currentStep: PropTypes.number,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  stepperComponent: PropTypes.object,
  isLastStep: PropTypes.bool,

  createEditorialChannel: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    editorialChannels: listEditorialChannels(state),
    importances: listImportancesSelector(state)
  };
}

const mapDispatchToProps = {
  createEditorialChannel: createEditorialChannelAction,
  addToast: addToastAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withStepper(
  AddEditorialChannelModal, [
    "Set editorial channel information",
    "Add logo(s)",
    "Sumup"
  ]));
